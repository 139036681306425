



































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IDownloadResults } from '@/store/types/IDownloadResults';
import {
  IntegrationType,
  IntegrationStorageType,
} from '@/store/types/Enums';

@Component
export default class ExportCompleteDownloadsSummaryTable extends Vue {
  get exportResult() {
    return ExportUiStateModule.completeExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }

  get downloadResult(): IDownloadResults {
    return this.exportResult!.publishResult.downloadResults;
  }
}
