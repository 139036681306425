

























































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IDownloadResults } from '@/store/types/IDownloadResults';
import {
  IntegrationType,
  IntegrationStorageType,
} from '@/store/types/Enums';
import { IIntegrationResults } from '@/store/types/IIntegrationResults';

@Component
export default class ExportCompleteIntegrationsSummaryTable extends Vue {
  get exportResult() {
    return ExportUiStateModule.completeExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }

  get integrationResults(): IIntegrationResults {
    return this.exportResult!.publishResult.integrationResults;
  }
}
