var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(!_vm.profile.HasActiveSubscription)?_c('exportupgradewarning'):_vm._e(),_vm._v(" "),(
        _vm.exportResult.publishResult.resultString === 'SomeFailed'
      )?_c('exportcompletefailurewarning'):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"title mt-6 mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE'))+"\n    ")]),_vm._v(" "),(
        _vm.exportResult.integrationExportSettings
          .ExportDocumentMode === 1
      )?_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('TL_SHARED_YOU_JUST_EXPORTED'))+"\n      "+_vm._s(_vm._f("number")(_vm.integrationResults.totalNeverPublishedBeforeRecordCount,'0,0'))+"\n      "+_vm._s(_vm.$t('TL_SHARED_HIGHLIGHTS_TO'))+"\n      "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+".\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.exportResult.integrationExportSettings.ExportDocumentMode !=
        1
      )?_c('div',[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('TL_SHARED_YOU_JUST_EXPORTED'))+"\n        "+_vm._s(_vm._f("number")(_vm.integrationResults.totalNeverPublishedBeforeRecordCount,'0,0'))+"\n        "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE_NEW_HIGHLIGHTS_TO'))+"\n        "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+".\n      ")]),_vm._v(" "),(
          _vm.integrationResults.totalPreviouslyPublishedRecordCount > 0
        )?_c('p',[_vm._v("\n        "+_vm._s(_vm._f("number")(_vm.integrationResults.totalPreviouslyPublishedRecordCount,'0,0'))+"\n        "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE_HIGHLIGHTS_WERE_IGNORED'))+"\n        "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+".\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-2"},[(
          _vm.exportResult.integration.integrationStorageTypeID == 1
        )?_c('b-container',[_c('b-row',[_c('b-col',[_c('img',{staticClass:"my-4",staticStyle:{"height":"36px","width":"36px"},attrs:{"src":require(("@/assets/images/integrations/" + (_vm.exportResult.integration.integrationName) + ".128x128.png")),"alt":""}}),_vm._v(" "),_c('font-awesome-icon',{staticClass:"align-middle mx-1",attrs:{"icon":"plus","size":"2x","fixed-width":""}}),_vm._v(" "),_c('img',{staticClass:"my-4",staticStyle:{"height":"36px","width":"36px"},attrs:{"src":require(("@/assets/images/integrations/" + (_vm.exportResult.exportFormatIntegration.integrationName) + ".128x128.png")),"alt":""}})],1)],1)],1):_vm._e(),_vm._v(" "),(
          _vm.exportResult.integration.integrationStorageTypeID == 2
        )?_c('img',{staticClass:"integrationimg",staticStyle:{"height":"36px","width":"36px"},attrs:{"src":require(("@/assets/images/integrations/" + (_vm.exportResult.integration.integrationName) + ".128x128.png")),"alt":""}}):_vm._e()],1),_vm._v(" "),_c('div',[(
          _vm.exportResult.integration.integrationName !==
          'AmazonKindle'
        )?_c('b-button',{staticClass:"my-4",attrs:{"href":_vm.exportResult.integration.integrationViewOnlineUrl,"size":"lg","variant":"success","target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE_VIEW_IN'))+"\n        "+_vm._s(_vm._f("uppercase")(_vm.exportResult.integration.integrationFriendlyName))+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.exportResult.integration.integrationName ===
          'AmazonKindle'
        )?_c('b-row',{staticClass:"my-4"},[_c('b-col',{attrs:{"cols":"2"}}),_vm._v(" "),_c('b-col',{attrs:{"cols":"8"}},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE_EBOOKS_MAY_ARRIVE'))+"\n            "),_c('a',{attrs:{"to":"'/settings/integrations/amazon'"}},[_vm._v("\n              "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE_AMAZON_SETTINGS_PAGE'))+" ")]),_vm._v(".\n          ")])]),_vm._v(" "),_c('b-col',{attrs:{"cols":"2"}})],1):_vm._e()],1),_vm._v(" "),_c('h4',{staticClass:"mb-4 mt-6"},[_vm._v("\n      "+_vm._s(_vm.$t('TL_SHARED_EXPORT_SUMMARY'))+"\n    ")]),_vm._v(" "),(
        _vm.exportResult.integrationExportSettings
          .ExportDocumentMode === 1
      )?_c('p',{staticClass:"mb-6"},[_vm._v("\n      "+_vm._s(_vm._f("number")(_vm.integrationResults.totalPublishedRecordCount))+"\n      "+_vm._s(_vm.$t('TL_SHARED_HIGHLIGHTS_IN'))+"\n      "+_vm._s(_vm._f("number")(_vm.integrationResults.publishedBooks.length,'0,0'))+"\n      "+_vm._s(_vm.$t('TL_SHARED_BOOKS_WERE_EXPORTED'))+"\n      "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+".\n    ")]):_c('p',{staticClass:"mb-6"},[_vm._v("\n      "+_vm._s(_vm._f("number")(_vm.integrationResults.totalNeverPublishedBeforeRecordCount,'0,0'))+"\n      "+_vm._s(_vm.$t('TL_SHARED_EXPORT_COMPLETE_NEW_HIGHLIGHTS_IN'))+"\n      "+_vm._s(_vm._f("number")(_vm.integrationResults.publishedBooks.length,'0,0'))+"\n      "+_vm._s(_vm.$t('TL_SHARED_BOOKS_WERE_EXPORTED'))+"\n      "+_vm._s(_vm.exportResult.integration.integrationFriendlyName)+".\n    ")]),_vm._v(" "),_c('exportcompleteintegrationssummarytable'),_vm._v(" "),_c('exportcompletefailures')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }