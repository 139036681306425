












import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import exportcompletedownloads from '@/pages/export/components/ExportCompleteDownloads.vue';
import exportcompleteintegrations from '@/pages/export/components/ExportCompleteIntegrations.vue';
import closeheader from '@/components/closeheader.vue';
@Component({
  components: {
    exportcompletedownloads,
    exportcompleteintegrations,
    closeheader,
  },
})
export default class ExportCompletePage extends Vue {
  get exportResult() {
    return ExportUiStateModule.completeExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }
}
