









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CloseHeader extends Vue {
  @Prop() to!: string;
}
