


























































































































































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import AuthModule from '@/store/modules/AuthModule';
import exportupgradewarning from '@/pages/export/components/ExportUpgradeWarning.vue';
import exportcompletefailurewarning from '@/pages/export/components/ExportCompleteFailureWarning.vue';
import exportcompleteintegrationssummarytable from '@/pages/export/components/ExportCompleteIntegrationsSummaryTable.vue';
import exportcompletefailures from '@/pages/export/components/ExportCompleteFailures.vue';
import { IIntegrationResults } from '@/store/types/IIntegrationResults';

@Component({
  components: {
    exportupgradewarning,
    exportcompletefailurewarning,
    exportcompleteintegrationssummarytable,
    exportcompletefailures,
  },
})
export default class ExportCompleteIntegrations extends Vue {
  get exportResult() {
    return ExportUiStateModule.completeExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }

  get integrationResults(): IIntegrationResults {
    return this.exportResult!.publishResult.integrationResults;
  }

  get profile() {
    return AuthModule.profile;
  }
}
