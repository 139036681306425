
































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import AuthModule from '@/store/modules/AuthModule';
import exportupgradewarning from '@/pages/export/components/ExportUpgradeWarning.vue';
import exportcompletefailurewarning from '@/pages/export/components/ExportCompleteFailureWarning.vue';
import exportcompletedownloadssummarytable from '@/pages/export/components/ExportCompleteDownloadsSummaryTable.vue';
import exportcompletefailures from '@/pages/export/components/ExportCompleteFailures.vue';
import { IDownloadResults } from '@/store/types/IDownloadResults';
import { IExportResult } from '@/store/types/IExportResult';

@Component({
  components: {
    exportupgradewarning,
    exportcompletefailurewarning,
    exportcompletedownloadssummarytable,
    exportcompletefailures,
  },
})
export default class ExportCompleteDownloads extends Vue {
  get exportResult() {
    return ExportUiStateModule.completeExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }

  get downloadResult(): IDownloadResults {
    return this.exportResult!.publishResult.downloadResults;
  }

  get profile() {
    return AuthModule.profile;
  }

  downloadFile() {
    window.location.href = this.downloadResult.downloadFilePath;
  }
}
